import React, { useState } from 'react';

function AddBatteryTypeForm({ onBatteryTypeAdded }) {
  const [batteryType, setBatteryType] = useState({
    brand: '',
    model: '',
    type: '',
    nominal_voltage: '',
    cdr: '',
    stated_capacity: '',
    button_top: false,  // Add default values
    protected: false,   // Add default values
    chemistry: '',      // Add chemistry field
    notes: ''           // Add notes field
  });

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    fetch('https://blackumbra.com/api/batterytypes', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(batteryType)
    })
    .then(response => {
      if (response.ok) {
        setMessage('Battery type added successfully');
        setBatteryType({
          brand: '',
          model: '',
          type: '',
          nominal_voltage: '',
          cdr: '',
          stated_capacity: '',
          button_top: false,  // Reset values
          protected: false,   // Reset values
          chemistry: '',      // Reset chemistry
          notes: ''           // Reset notes
        });
        if (onBatteryTypeAdded && typeof onBatteryTypeAdded === 'function') {
          onBatteryTypeAdded(); // Trigger the refresh of battery types in the parent
        }
      } else {
        response.json().then(data => setError(data.message || 'Failed to add battery type'));
      }
    })
    .catch(err => setError('Error: Failed to submit battery type.'));
  };

  return (
    <form onSubmit={handleSubmit}>
      <input 
        name="brand" 
        value={batteryType.brand} 
        onChange={(e) => setBatteryType({...batteryType, brand: e.target.value})} 
        placeholder="Brand" 
      />
      <input 
        name="model" 
        value={batteryType.model} 
        onChange={(e) => setBatteryType({...batteryType, model: e.target.value})} 
        placeholder="Model" 
      />
      <input 
        name="type" 
        value={batteryType.type} 
        onChange={(e) => setBatteryType({...batteryType, type: e.target.value})} 
        placeholder="Type" 
      />
      <input 
        name="nominal_voltage" 
        type="number" 
        step="0.01" 
        value={batteryType.nominal_voltage} 
        onChange={(e) => setBatteryType({...batteryType, nominal_voltage: e.target.value})} 
        placeholder="Nominal Voltage" 
      />
      <input 
        name="cdr" 
        type="number" 
        value={batteryType.cdr} 
        onChange={(e) => setBatteryType({...batteryType, cdr: e.target.value})} 
        placeholder="CDR" 
      />
      <input 
        name="stated_capacity" 
        type="number" 
        value={batteryType.stated_capacity} 
        onChange={(e) => setBatteryType({...batteryType, stated_capacity: e.target.value})} 
        placeholder="Capacity" 
      />
      
      <select 
        name="chemistry" 
        value={batteryType.chemistry} 
        onChange={(e) => setBatteryType({...batteryType, chemistry: e.target.value})}
      >
        <option value="">Select Chemistry</option>
        <option value="Li-ion">Li-ion</option>
        <option value="NiMH">NiMH</option>
        <option value="Alkaline">Alkaline</option>
        {/* Add other chemistries as needed */}
      </select>

      <label>
        <input 
          type="checkbox" 
          name="button_top" 
          checked={batteryType.button_top} 
          onChange={(e) => setBatteryType({...batteryType, button_top: e.target.checked})}
        />
        Button Top
      </label>

      <label>
        <input 
          type="checkbox" 
          name="protected" 
          checked={batteryType.protected} 
          onChange={(e) => setBatteryType({...batteryType, protected: e.target.checked})}
        />
        Protected
      </label>

      <textarea 
        name="notes" 
        value={batteryType.notes} 
        onChange={(e) => setBatteryType({...batteryType, notes: e.target.value})} 
        placeholder="Notes" 
        rows="3" // Optional: adjust rows as needed
      />

      <button type="submit">Add Battery Type</button>

      {error && <p style={{color: 'red'}}>{error}</p>}
      {message && <p style={{color: 'green'}}>{message}</p>}
    </form>
  );
}

export default AddBatteryTypeForm;
