import React, { useEffect, useState } from 'react';
import FlashlightBatteryAssignmentTable from '../components/FlashlightBatteryAssignmentTable';  // Ensure the path is correct
import AssignBatteryForm from '../components/AssignBatteryForm';  // Import the AssignBatteryForm component
import { fetchFlashlights } from '../services/flashlightService'; // Import the service to fetch flashlights

function BatteryAssignmentPage() {
    const [assignments, setAssignments] = useState([]);
    const [flashlights, setFlashlights] = useState([]); // Add state for flashlights
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(false); // Add refresh trigger

    // Fetch assignments data
    useEffect(() => {
        fetch('https://blackumbra.com/api/flashlight-battery-assignments')
            .then(response => response.json())
            .then(data => {
                setAssignments(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching battery assignments:', err);
                setError('Failed to load battery assignments.');
                setLoading(false);
            });
    }, [refreshTrigger]); // Reload assignments when refreshTrigger changes

    // Fetch flashlights data
    useEffect(() => {
        fetchFlashlights()
            .then(data => {
                setFlashlights(data); // Set the fetched flashlights data to state
            })
            .catch(err => {
                console.error('Error fetching flashlights:', err);
                setError('Failed to load flashlights.');
            });
    }, []); // Only fetch flashlights once on mount

    const handleAssignmentChanged = () => {
        setRefreshTrigger(prev => !prev); // Toggle refresh trigger to reload assignments
    };

    if (loading) {
        return <p>Loading battery assignments...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Battery Assignments</h2>
            <AssignBatteryForm
                flashlights={flashlights} // Pass flashlights data
                onAssignmentChanged={handleAssignmentChanged} // Pass callback
                refreshTrigger={refreshTrigger} // Pass refresh trigger
            />
            <FlashlightBatteryAssignmentTable assignments={assignments} />
        </div>
    );
}

export default BatteryAssignmentPage;
