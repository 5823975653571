import React, { useState, useEffect } from 'react';

function AddEmitterTestResultsForm({ flashlights, onEmitterTestAdded }) {
    const [flashlightId, setFlashlightId] = useState('');
    const [emitters, setEmitters] = useState([]);
    const [emitter, setEmitter] = useState({
        emitter_id: '',
        measured_cct: '',
        measured_ra: '',
        measured_r9: '',
        measured_duv: '',
        measured_lux: '',
        distance_tested: '',
        notes: '' // Add notes to the emitter state
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const fetchEmitters = (flashlightId) => {
        fetch(`https://blackumbra.com/api/flashlights/${flashlightId}/emitters`)
            .then(response => response.json())
            .then(data => setEmitters(data))
            .catch(err => setError('Error fetching emitters'));
    };

    const handleFlashlightChange = (e) => {
        const selectedFlashlightId = e.target.value;
        setFlashlightId(selectedFlashlightId);
        setEmitters([]);  // Clear emitters when changing flashlights
        if (selectedFlashlightId) {
            fetchEmitters(selectedFlashlightId);
        }
    };

    const handleEmitterChange = (e) => {
        const { name, value } = e.target;
        setEmitter(prev => ({ ...prev, [name]: value }));  // Update the emitter state directly
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        fetch('https://blackumbra.com/api/emitter-test-results', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(emitter),
        })
            .then(response => {
                if (response.ok) {
                    setMessage('Emitter test results added successfully');
                    setEmitter({
                        emitter_id: '',
                        measured_cct: '',
                        measured_ra: '',
                        measured_r9: '',
                        measured_duv: '',
                        measured_lux: '',
                        distance_tested: '',
                        notes: '' // Reset notes
                    });
                    onEmitterTestAdded();  // Trigger refresh of the table after submission
                } else {
                    response.json().then(data => setError(`Error: ${data.message}`));
                }
            })
            .catch(err => {
                console.error('Error adding emitter test results:', err);
                setError('Error adding emitter test results');
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Add Emitter Test Results</h2>

            <label>Flashlight:</label>
            <select name="flashlight_id" value={flashlightId} onChange={handleFlashlightChange}>
                <option value="">Select Flashlight</option>
                {flashlights.map(flashlight => (
                    <option key={flashlight.id} value={flashlight.id}>
                        {flashlight.nickname
                            ? flashlight.nickname
                            : `${flashlight.make} ${flashlight.model} ${flashlight.headstamp ? `(Headstamp: ${flashlight.headstamp})` : `(ID: ${flashlight.id})`}`}
                    </option>
                ))}
            </select>

            {flashlightId && emitters.length > 0 && (
                <>
                    <label>Emitter:</label>
                    <select name="emitter_id" value={emitter.emitter_id} onChange={handleEmitterChange}>
                        <option value="">Select Emitter</option>
                        {emitters.map(em => (
                            <option key={em.id} value={em.id}>
                                {em.type} (Driver: {em.driver_type})
                            </option>
                        ))}
                    </select>
                </>
            )}

            <label>Measured CCT:</label>
            <input
                name="measured_cct"
                type="number"
                value={emitter.measured_cct}
                onChange={handleEmitterChange}
                placeholder="Measured CCT"
                step="1"  // Ensure that the value steps in whole numbers
                min="0"   // Optional: Define a minimum value if required
            />

            <label>Measured Ra:</label>
            <input name="measured_ra" value={emitter.measured_ra} onChange={handleEmitterChange} />

            <label>Measured R9:</label>
            <input name="measured_r9" type="number" value={emitter.measured_r9} onChange={handleEmitterChange} />

            <label>Measured DUV:</label>
            <input name="measured_duv" type="number" value={emitter.measured_duv} onChange={handleEmitterChange} />

            <label>Measured Lux:</label>
            <input name="measured_lux" type="number" value={emitter.measured_lux} onChange={handleEmitterChange} />

            <label>Distance Tested (m):</label>
            <input name="distance_tested" type="number" value={emitter.distance_tested} onChange={handleEmitterChange} />

            <label>Notes:</label>
            <textarea
                name="notes"
                value={emitter.notes}
                onChange={handleEmitterChange}
                placeholder="Additional notes or observations"
                rows="3" // Optional: adjust rows as needed
            />

            <button type="submit">Submit</button>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
    );
}

export default AddEmitterTestResultsForm;
