import React, { useState, useEffect } from 'react';
import AddEmitterTestResultsForm from '../components/AddEmitterTestResultsForm';  // Ensure the path is correct
import EmitterTestResultsTable from '../components/EmitterTestResultsTable';  // Ensure the path is correct

function EmitterTestingPage() {
    const [emitterTests, setEmitterTests] = useState([]);
    const [flashlights, setFlashlights] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Fetch flashlights and emitter test results
    useEffect(() => {
        Promise.all([
            fetch('https://blackumbra.com/api/flashlights').then(res => res.json()),
            fetch('https://blackumbra.com/api/emitter-test-results').then(res => res.json()),
        ])
            .then(([flashlightsData, emitterTestsData]) => {
                setFlashlights(flashlightsData);
                setEmitterTests(emitterTestsData);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching emitter test data:', err);
                setError('Failed to load emitter test data.');
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading emitter test data...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Emitter Testing</h2>
            <AddEmitterTestResultsForm flashlights={flashlights} onEmitterTestAdded={() => {/* Trigger refresh */}} />
            <EmitterTestResultsTable emitterTests={emitterTests} />
        </div>
    );
}

export default EmitterTestingPage;
