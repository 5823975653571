import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import navigation hook
import { fetchFlashlights } from '../services/flashlightService';
import { fetchEmitterTestResults } from '../services/emitterService'; // Import emitter test results function

function FlashlightTable({ refreshTrigger }) {
    const [flashlights, setFlashlights] = useState([]);
    const [error, setError] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        fetchFlashlights()
            .then(data => {
                setFlashlights(data);
            })
            .catch(() => setError('Failed to load flashlight data.'));
    }, [refreshTrigger]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedFlashlights = [...flashlights].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleViewEmitters = (flashlightId) => {
        navigate(`/flashlights/${flashlightId}/emitters`); // Navigate to the emitter results for this flashlight
    };

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Flashlight List</h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('id')}>ID</th>
                        <th onClick={() => handleSort('make')}>Make</th>
                        <th onClick={() => handleSort('model')}>Model</th>
                        <th onClick={() => handleSort('battery_size')}>Battery Size</th>
                        <th onClick={() => handleSort('battery_chemistry')}>Battery Chemistry</th>
                        <th onClick={() => handleSort('ip_rating')}>IP Rating</th>
                        <th onClick={() => handleSort('date_acquired')}>Date Acquired</th>
                        <th onClick={() => handleSort('nickname')}>Nickname</th>
                        <th onClick={() => handleSort('headstamp')}>Headstamp</th>
                        <th onClick={() => handleSort('max_slots')}>Max Slots</th>
                        <th>Notes</th>
                        <th>Actions</th> {/* New column for actions */}
                    </tr>
                </thead>
                <tbody>
                    {sortedFlashlights.map(flashlight => (
                        <tr key={flashlight.id}>
                            <td>{flashlight.id}</td>
                            <td>{flashlight.make || 'N/A'}</td>
                            <td>{flashlight.model || 'N/A'}</td>
                            <td>{flashlight.battery_size || 'N/A'}</td>
                            <td>{flashlight.battery_chemistry || 'N/A'}</td>
                            <td>{flashlight.ip_rating || 'N/A'}</td>
                            <td>{flashlight.date_acquired ? new Date(flashlight.date_acquired).toLocaleDateString() : 'N/A'}</td>
                            <td>{flashlight.nickname || 'N/A'}</td>
                            <td>{flashlight.headstamp || 'N/A'}</td>
                            <td>{flashlight.max_slots || 'N/A'}</td>
                            <td>{flashlight.notes || 'N/A'}</td>
                            <td>
                                {/* Pass the flashlight ID instead of undefined emitter */}
                                <button onClick={() => handleViewEmitters(flashlight.id)}>View Test Results</button>
                            </td> {/* Button to view emitter test results */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightTable;
