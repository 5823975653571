// src/pages/FlashlightsPage.js
import React, { useState, useEffect } from 'react';
import FlashlightTable from '../components/FlashlightTable';
import AddFlashlightForm from '../components/AddFlashlightForm';
import { fetchFlashlights } from '../services/flashlightService';

function FlashlightsPage() {
    const [flashlights, setFlashlights] = useState([]);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    useEffect(() => {
        fetchFlashlights()
            .then(data => setFlashlights(data))
            .catch(err => console.error('Error fetching flashlights:', err));
    }, [refreshTrigger]);

    const handleFlashlightAdded = () => {
        setRefreshTrigger(prev => !prev);
    };

    return (
        <div>
            <h1>Flashlights</h1>
            <AddFlashlightForm onFlashlightAdded={handleFlashlightAdded} />
            <FlashlightTable flashlights={flashlights} />
        </div>
    );
}

export default FlashlightsPage;
