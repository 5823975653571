import React from 'react';

function FlashlightBatteryAssignmentTable({ assignments, onAssignmentRemoved }) {
    const handleRemove = (flashlightId, batterySlot) => {
        fetch(`https://blackumbra.com/api/flashlights/${flashlightId}/remove-battery`, {
            method: 'DELETE',
            body: JSON.stringify({ battery_slot: batterySlot }),  // Pass the slot number when removing
            headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
            if (response.ok) {
                alert('Battery assignment removed successfully!');
                onAssignmentRemoved();  // Trigger refresh of assignments
            } else {
                alert('Failed to remove battery assignment.');
            }
        })
        .catch(error => console.error('Error removing battery assignment:', error));
    };

    return (
        <div>
            <h2>Battery Assignments</h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th>Flashlight</th>
                        <th>Battery</th>
                        <th>Slot</th> {/* Display the slot number */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {assignments.length > 0 ? (
                        assignments.map((assignment, index) => (
                            <tr key={index}>
                                <td>{assignment.flashlight_make} {assignment.flashlight_model}</td>
                                <td>{assignment.battery_barcode}</td>
                                <td>{assignment.battery_slot}</td> {/* Display the slot */}
                                <td>
                                    <button onClick={() => handleRemove(assignment.flashlight_id, assignment.battery_slot)}>
                                        Remove Assignment
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No assignments available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightBatteryAssignmentTable;
