import React, { useState, useEffect } from 'react';

function AssignBatteryForm({ flashlights = [], onAssignmentChanged, refreshTrigger }) {
    const [selectedFlashlight, setSelectedFlashlight] = useState('');
    const [selectedBattery, setSelectedBattery] = useState('');
    const [selectedSlot, setSelectedSlot] = useState(1);
    const [batteries, setBatteries] = useState([]);

    // Fetch batteries when component mounts or refreshTrigger changes
    useEffect(() => {
        fetch('https://blackumbra.com/api/batteries')
            .then(response => response.json())
            .then(data => setBatteries(data || []))
            .catch(error => console.error('Error fetching batteries:', error));
    }, [refreshTrigger]);

    const handleAssign = (e) => {
        e.preventDefault();
        if (!selectedFlashlight || !selectedBattery) {
            alert('Please select both a flashlight and a battery.');
            return;
        }

        fetch(`https://blackumbra.com/api/flashlights/${selectedFlashlight}/assign-battery`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ battery_id: selectedBattery, battery_slot: selectedSlot }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Failed to assign battery: ${response.statusText}`);
            }
            return response.json();
        })
        .then(() => {
            alert('Battery assigned successfully!');
            setSelectedFlashlight('');
            setSelectedBattery('');
            setSelectedSlot(1);
            onAssignmentChanged(); // Notify parent component of change
        })
        .catch(error => {
            console.error('Error assigning battery:', error.message);
            alert(`Error assigning battery: ${error.message}`);
        });
    };

    return (
        <form onSubmit={handleAssign}>
            <label>Select Flashlight:</label>
            <select name="flashlight_id" value={selectedFlashlight} onChange={e => setSelectedFlashlight(e.target.value)}>
                <option value="">-- Select Flashlight --</option>
                {flashlights.map(flashlight => (
                    <option key={flashlight.id} value={flashlight.id}>
                        {flashlight.nickname
                            ? flashlight.nickname
                            : `${flashlight.make} ${flashlight.model} ${flashlight.headstamp ? `(Headstamp: ${flashlight.headstamp})` : `(ID: ${flashlight.id})`}`}
                    </option>
                ))}
            </select>

            <label>Select Battery:</label>
            <select name="battery_id" value={selectedBattery} onChange={e => setSelectedBattery(e.target.value)}>
                <option value="">-- Select Battery --</option>
                {batteries.length > 0
                    ? batteries.map(battery => (
                          <option key={battery.id} value={battery.id}>
                              {battery.barcode || `Battery ID: ${battery.id}`}
                          </option>
                      ))
                    : <option disabled>No batteries available</option>}
            </select>

            <label>Battery Slot:</label>
            <input
                type="number"
                name="battery_slot"
                min="1"
                value={selectedSlot}
                onChange={(e) => setSelectedSlot(e.target.value)}
                placeholder="Slot Number"
            />

            <button type="submit">Assign Battery</button>
        </form>
    );
}

export default AssignBatteryForm;
