// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import FlashlightsPage from './pages/FlashlightsPage';
import BatteriesPage from './pages/BatteriesPage';
import BatteryAssignmentPage from './pages/BatteryAssignmentPage';
import EmitterTestingPage from './pages/EmitterTestingPage';
import FlashlightEmitterTestsPage from './pages/FlashlightEmitterTestsPage'; // Import this component


function App() {
    return (
        <Router>
            <Navbar />
            <Routes> {/* Make sure to use 'Routes' instead of 'Route' for wrapping */}
                <Route path="/flashlights" element={<FlashlightsPage />} />
                <Route path="/batteries" element={<BatteriesPage />} />
                <Route path="/assignments" element={<BatteryAssignmentPage />} />
                <Route path="/emitters" element={<EmitterTestingPage />} />
                <Route path="/flashlights/:flashlightId/emitters" element={<FlashlightEmitterTestsPage />} />
                <Route path="/" element={<FlashlightsPage />} /> {/* Set default route */}
            </Routes>
        </Router>
    );
}

export default App;
