import React, { useState, useEffect } from 'react';

function BatteryTable() {
    const [batteries, setBatteries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetch('https://blackumbra.com/api/batteries')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch batteries');
                }
                return response.json();
            })
            .then(data => {
                setBatteries(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching batteries:', error);
                setError('Failed to load batteries.');
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading batteries...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Batteries</h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th>Barcode</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Type</th>
                        <th>Date Acquired</th>
                        <th>Last Test Date</th>
                        <th>Notes</th> {/* Add header for Notes */}
                    </tr>
                </thead>
                <tbody>
                    {batteries.map((battery) => (
                        <tr key={battery.id}>
                            <td>{battery.barcode}</td>
                            <td>{battery.brand}</td>
                            <td>{battery.model}</td>
                            <td>{battery.type}</td>
                            <td>{battery.date_acquired ? new Date(battery.date_acquired).toLocaleDateString() : 'N/A'}</td>
                            <td>{battery.last_test_date ? new Date(battery.last_test_date).toLocaleDateString() : 'N/A'}</td>
                            <td>{battery.notes || 'N/A'}</td> {/* Display notes */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BatteryTable;
