// src/pages/BatteriesPage.js
import React, { useState, useEffect } from 'react';
import BatteryTable from '../components/BatteryTable';
import BatteryTypesTable from '../components/BatteryTypesTable';
import AddBatteryForm from '../components/AddBatteryForm';
import AddBatteryTypeForm from '../components/AddBatteryTypeForm';
import { fetchBatteries, fetchBatteryTypes } from '../services/batteryService';

function BatteriesPage() {
    const [batteries, setBatteries] = useState([]);
    const [batteryTypes, setBatteryTypes] = useState([]);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    useEffect(() => {
        fetchBatteries()
            .then(data => setBatteries(data))
            .catch(err => console.error('Error fetching batteries:', err));

        fetchBatteryTypes()
            .then(data => setBatteryTypes(data))
            .catch(err => console.error('Error fetching battery types:', err));
    }, [refreshTrigger]);

    const handleBatteryAdded = () => {
        setRefreshTrigger(prev => !prev);
    };

    return (
        <div>
            <h1>Batteries</h1>
            <AddBatteryForm batteryTypes={batteryTypes} onBatteryAdded={handleBatteryAdded} />
            <BatteryTable batteries={batteries} />
            <h1>Battery Types</h1>
            <AddBatteryTypeForm onBatteryTypeAdded={handleBatteryAdded} />
            <BatteryTypesTable batteryTypes={batteryTypes} />
        </div>
    );
}

export default BatteriesPage;
