import React, { useState, useEffect } from 'react';

function BatteryTypesTable() {
    const [batteryTypes, setBatteryTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetch('https://blackumbra.com/api/batterytypes')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch battery types');
                }
                return response.json();
            })
            .then(data => {
                setBatteryTypes(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching battery types:', error);
                setError('Failed to load battery types.');
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading battery types...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Battery Types</h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Type</th>
                        <th>Nominal Voltage</th>
                        <th>CDR</th>
                        <th>Capacity</th>
                        <th>Chemistry</th>
                        <th>Button Top</th>
                        <th>Protected</th>
                        <th>Notes</th> {/* New Notes Column Header */}
                    </tr>
                </thead>
                <tbody>
                    {batteryTypes.map((batteryType) => (
                        <tr key={batteryType.id}>
                            <td>{batteryType.brand}</td>
                            <td>{batteryType.model}</td>
                            <td>{batteryType.type}</td>
                            <td>{batteryType.nominal_voltage || 'N/A'}</td>
                            <td>{batteryType.cdr || 'N/A'}</td>
                            <td>{batteryType.stated_capacity || 'N/A'}</td>
                            <td>{batteryType.chemistry || 'N/A'}</td>
                            <td>{batteryType.button_top ? 'Yes' : 'No'}</td>
                            <td>{batteryType.protected ? 'Yes' : 'No'}</td>
                            <td>{batteryType.notes || 'N/A'}</td> {/* Display the notes */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BatteryTypesTable;
