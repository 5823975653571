export async function fetchEmitterTestsByFlashlightId(flashlightId) {
    try {
        const response = await fetch(`https://blackumbra.com/api/flashlights/${flashlightId}/emitters`);
        if (!response.ok) {
            throw new Error(`Error fetching emitter tests: ${response.statusText} (status: ${response.status})`);
        }
        const data = await response.json();
        console.log('Fetched emitter test results from API:', data); // Log the fetched data from API
        return data;
    } catch (err) {
        console.error('Error fetching emitter test results by flashlight ID:', err);
        throw err;
    }
}
