import React, { useState, useEffect } from 'react';
import { fetchEmitterTestResults } from '../services/emitterService';

function EmitterTestResultsTable({ refreshTrigger }) {
    const [emitterTests, setEmitterTests] = useState([]);
    const [error, setError] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        fetchEmitterTestResults()
            .then(data => setEmitterTests(data))
            .catch(() => setError('Failed to load emitter test results.'));
    }, [refreshTrigger]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedResults = [...emitterTests].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <h2>Emitter Test Results</h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('emitter_id')}>
                            Emitter ID {sortConfig.key === 'emitter_id' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('measured_cct')}>
                            Measured CCT {sortConfig.key === 'measured_cct' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('measured_ra')}>
                            Measured Ra {sortConfig.key === 'measured_ra' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('measured_r9')}>
                            Measured R9 {sortConfig.key === 'measured_r9' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('measured_duv')}>
                            Measured DUV {sortConfig.key === 'measured_duv' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('measured_lux')}>
                            Measured Lux {sortConfig.key === 'measured_lux' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('distance_tested')}>
                            Distance Tested {sortConfig.key === 'distance_tested' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('test_date')}>
                            Test Date {sortConfig.key === 'test_date' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th>
                        <th onClick={() => handleSort('notes')}>
                            Notes {sortConfig.key === 'notes' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                        </th> {/* New Notes Column Header */}
                    </tr>
                </thead>
                <tbody>
                    {sortedResults.map((result) => (
                        <tr key={result.id}>
                            <td>{result.emitter_id}</td>
                            <td>{result.measured_cct}</td>
                            <td>{result.measured_ra}</td>
                            <td>{result.measured_r9}</td>
                            <td>{result.measured_duv}</td>
                            <td>{result.measured_lux}</td>
                            <td>{result.distance_tested}</td>
                            <td>{new Date(result.test_date).toLocaleDateString()}</td>
                            <td>{result.notes || 'N/A'}</td> {/* New Notes Data Column */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default EmitterTestResultsTable;
