// services/batteryService.js
export const fetchBatteries = () => {
    return fetch('https://blackumbra.com/api/batteries')
        .then(response => response.json())
        .catch(err => {
            console.error('Error fetching batteries:', err);
            throw err;
        });
};

export const fetchBatteryTypes = () => {
    return fetch('https://blackumbra.com/api/batterytypes')
        .then(response => response.json())
        .catch(err => {
            console.error('Error fetching battery types:', err);
            throw err;
        });
};
