import React, { useState } from 'react';

function AddBatteryForm({ batteryTypes = [], onBatteryAdded }) {  // Add onBatteryAdded as a prop
  const [battery, setBattery] = useState({
    barcode: '',
    date_acquired: '',
    last_test_date: '',
    type_id: '',
    notes: '' // Add notes to the battery state
  });

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    fetch('https://blackumbra.com/api/batteries', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(battery)
    })
    .then(response => {
      if (response.ok) {
        setMessage('Battery added successfully');
        setBattery({
          barcode: '',
          date_acquired: '',
          last_test_date: '',
          type_id: '',
          notes: '' // Reset notes field
        });

        if (onBatteryAdded && typeof onBatteryAdded === 'function') {
          onBatteryAdded();  // Trigger callback to refresh battery list
        }
      } else {
        return response.json().then(data => {
          setError(data.message || 'Failed to add battery');
        });
      }
    })
    .catch(err => {
      setError('Error: Failed to submit battery.');
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input 
        name="barcode" 
        value={battery.barcode} 
        onChange={(e) => setBattery({...battery, barcode: e.target.value})} 
        placeholder="Barcode" 
      />
      <input 
        name="date_acquired" 
        type="date" 
        value={battery.date_acquired} 
        onChange={(e) => setBattery({...battery, date_acquired: e.target.value})} 
      />
      <input 
        name="last_test_date" 
        type="date" 
        value={battery.last_test_date} 
        onChange={(e) => setBattery({...battery, last_test_date: e.target.value})} 
      />

      <select 
        name="type_id" 
        value={battery.type_id} 
        onChange={(e) => setBattery({...battery, type_id: e.target.value})}
      >
        <option value="">Select Battery Type</option>
        {batteryTypes.length > 0 ? (
          batteryTypes.map(type => (
            <option key={type.id} value={type.id}>
              {type.brand} {type.model}
            </option>
          ))
        ) : (
          <option disabled>No battery types available</option>
        )}
      </select>

      <input
        name="notes" 
        value={battery.notes} 
        onChange={(e) => setBattery({...battery, notes: e.target.value})} 
        placeholder="Notes" // Add input for notes
      />

      <button type="submit">Add Battery</button>

      {error && <p style={{color: 'red'}}>{error}</p>}
      {message && <p style={{color: 'green'}}>{message}</p>}
    </form>
  );
}

export default AddBatteryForm;
