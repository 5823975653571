import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchEmitterTestsByFlashlightId } from '../services/emitterService';

function FlashlightEmitterTestsPage() {
    const { flashlightId } = useParams();
    const [emitterTests, setEmitterTests] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        console.log('Fetching emitter tests for Flashlight ID:', flashlightId);
        fetchEmitterTestsByFlashlightId(flashlightId)
            .then(data => {
                console.log('Fetched emitter tests:', data); // Log all fetched tests
                setEmitterTests(data); // Set the entire array of results
            })
            .catch(err => {
                console.error('Error fetching emitter tests:', err);
                setError('Failed to load emitter tests.');
            });
    }, [flashlightId]);

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    if (!emitterTests.length) {
        return <p>No emitter test results available for this flashlight.</p>;
    }

    return (
        <div>
            <h2>Emitter Test Results for Flashlight ID: {flashlightId}</h2>
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th>Emitter ID</th>
                        <th>Type</th>
                        <th>Driver Type</th>
                        <th>Max Lumens</th>
                        <th>Max Throw</th>
                        <th>Stated CCT</th>
                        <th>Measured CCT</th>
                        <th>Measured Ra</th>
                        <th>Measured R9</th>
                        <th>Measured DUV</th>
                        <th>Measured Lux</th>
                        <th>Distance Tested</th>
                        <th>Test Date</th>
                    </tr>
                </thead>
                <tbody>
                    {emitterTests.map((testResult) => (
                        <tr key={testResult.id}>
                            <td>{testResult.id || 'N/A'}</td>
                            <td>{testResult.type || 'N/A'}</td>
                            <td>{testResult.driver_type || 'N/A'}</td>
                            <td>{testResult.max_lumens || 'N/A'}</td>
                            <td>{testResult.max_throw || 'N/A'}</td>
                            <td>{testResult.stated_cct || 'N/A'}</td>
                            <td>{testResult.measured_cct || 'N/A'}</td>
                            <td>{testResult.measured_ra || 'N/A'}</td>
                            <td>{testResult.measured_r9 || 'N/A'}</td>
                            <td>{testResult.measured_duv || 'N/A'}</td>
                            <td>{testResult.measured_lux || 'N/A'}</td>
                            <td>{testResult.distance_tested || 'N/A'}</td>
                            <td>{testResult.test_date ? new Date(testResult.test_date).toLocaleDateString() : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightEmitterTestsPage;
