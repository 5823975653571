import React, { useState, useEffect } from 'react';
import { removeBatteryAssignment, fetchAssignments } from '../services/assignmentService';
import { fetchBatteries, fetchBatteryTypes } from '../services/batteryService';
import { fetchFlashlights } from '../services/flashlightService';

function FlashlightBatteryAssignmentTable({ onAssignmentRemoved }) {
    const [sortConfig, setSortConfig] = useState({ key: 'flashlight_make', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');
    const [batteries, setBatteries] = useState([]);
    const [batteryTypes, setBatteryTypes] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [flashlights, setFlashlights] = useState([]);  // New state for flashlights

    // Fetch batteries, battery types, flashlights, and assignments when the component mounts
    useEffect(() => {
        fetchBatteries()
            .then(fetchedBatteries => {
                console.log('Fetched Batteries:', fetchedBatteries);
                setBatteries(fetchedBatteries);
            })
            .catch(error => console.error('Error fetching batteries:', error));

        fetchBatteryTypes()
            .then(fetchedBatteryTypes => {
                console.log('Fetched Battery Types:', fetchedBatteryTypes);
                setBatteryTypes(fetchedBatteryTypes);
            })
            .catch(error => console.error('Error fetching battery types:', error));

        fetchAssignments()
            .then(fetchedAssignments => {
                console.log('Fetched Assignments:', fetchedAssignments);
                setAssignments(fetchedAssignments);
            })
            .catch(error => console.error('Error fetching assignments:', error));

        fetchFlashlights()  // Fetch flashlights
            .then(fetchedFlashlights => {
                console.log('Fetched Flashlights:', fetchedFlashlights);
                setFlashlights(fetchedFlashlights);  // Save flashlights to state
            })
            .catch(error => console.error('Error fetching flashlights:', error));
    }, []);

    // Sorting logic
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getBatteryInfo = (batteryBarcode) => {
        const battery = batteries.find(b => b.barcode === batteryBarcode);
        if (!battery) return 'Unknown Battery';

        const batteryType = batteryTypes.find(type => type.id === battery.type_id);
        if (!batteryType) return 'Unknown Type';

        return `${batteryType.brand || ''} ${batteryType.model || ''} ${batteryType.stated_capacity ? batteryType.stated_capacity + 'mAh' : ''}`;
    };

    const getFlashlightInfo = (assignment) => {
        const flashlight = flashlights.find(f => f.id === assignment.flashlight_id);
        if (!flashlight) return 'Unknown Flashlight';

        const { make, model, nickname } = flashlight;
        const extraInfo = nickname ? ` (${nickname})` : '';
        return `${make} ${model || ''}${extraInfo}`;
    };

    // Handle removal of the battery assignment
    const handleRemoveAssignment = (flashlightId, batterySlot) => {
        removeBatteryAssignment(flashlightId, batterySlot)
            .then(() => {
                // Update the state to remove the assignment from the UI
                setAssignments(prevAssignments =>
                    prevAssignments.filter(
                        (assignment) => !(assignment.flashlight_id === flashlightId && assignment.battery_slot === batterySlot)
                    )
                );

                // Optionally, trigger any callback passed via props
                if (onAssignmentRemoved) {
                    onAssignmentRemoved(flashlightId, batterySlot);
                }

                alert('Assignment removed successfully!');
            })
            .catch((error) => {
                console.error('Error removing assignment:', error);
                alert('Failed to remove assignment.');
            });
    };

    // Filter assignments based on search input
    const filteredAssignments = assignments.filter((assignment) => {
        const batteryInfo = getBatteryInfo(assignment.battery_barcode).toLowerCase();
        const flashlightInfo = getFlashlightInfo(assignment).toLowerCase();
        return batteryInfo.includes(searchTerm.toLowerCase()) || flashlightInfo.includes(searchTerm.toLowerCase());
    });

    return (
        <div>
            <h2>Battery Assignments</h2>
            {/* Display count of filtered assignments */}
            <p>
                Showing {filteredAssignments.length} of {assignments.length} assignments
            </p>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px' }}
            />
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('flashlight_make')}>
                            Flashlight {sortConfig.key === 'flashlight_make' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_brand')}>
                            Battery {sortConfig.key === 'battery_brand' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_barcode')}>
                            Barcode {sortConfig.key === 'battery_barcode' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th onClick={() => handleSort('battery_slot')}>
                            Slot {sortConfig.key === 'battery_slot' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAssignments.length > 0 ? (
                        filteredAssignments.map((assignment, index) => (
                            <tr key={index}>
                                <td>{getFlashlightInfo(assignment)}</td>
                                <td>{getBatteryInfo(assignment.battery_barcode)}</td>
                                <td>{assignment.battery_barcode || 'N/A'}</td>
                                <td>{assignment.battery_slot || 'N/A'}</td>
                                <td>
                                    <button onClick={() => handleRemoveAssignment(assignment.flashlight_id, assignment.battery_slot)}>
                                        Remove Assignment
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No assignments available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default FlashlightBatteryAssignmentTable;
