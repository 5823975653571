import React, { useState, useEffect } from 'react';

function AddFlashlightForm({ refreshBatteryList, onFlashlightAdded }) {  // Add onFlashlightAdded as a prop
    const [flashlight, setFlashlight] = useState({
        make: '',
        model: '',
        battery_size: '',
        battery_chemistry: '',
        max_slots: 1,  // Default value for max slots
        ip_rating: '',
        date_acquired: '',
        nickname: '',  // Add nickname field
        headstamp: '',  // Add headstamp field
        notes: '',
        emitters: [{ type: '', driver_type: '', max_lumens: '', max_throw: '', stated_cct: '', measured_cct: '', measured_ra: '', measured_duv: '', measured_lux: '', distance_tested: '' }],
        assigned_battery_id: ''
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [availableBatteries, setAvailableBatteries] = useState([]); // Initialize with an empty array

    // Fetch available batteries
    const fetchBatteries = () => {
        fetch('https://blackumbra.com/api/batteries')
            .then(response => response.json())
            .then(data => {
                if (data.length === 0) {
                    setAvailableBatteries([]); // Set to an empty array if no data
                    setMessage('No batteries available');
                } else {
                    setAvailableBatteries(data); // Populate batteries
                }
            })
            .catch((error) => {
                console.error('Error fetching batteries:', error);
                setError('Failed to load batteries');
            });
    };

    // Re-fetch batteries when component mounts or when refreshBatteryList changes
    useEffect(() => {
        fetchBatteries();
    }, [refreshBatteryList]);  // Dependency on refreshBatteryList

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFlashlight({ ...flashlight, [name]: value });
    };

    // Handle emitter changes
    const handleEmitterChange = (e, index) => {
        const { name, value } = e.target;
        const updatedEmitters = flashlight.emitters.map((emitter, i) =>
            i === index ? { ...emitter, [name]: value } : emitter
        );
        setFlashlight({ ...flashlight, emitters: updatedEmitters });
    };

    // Add a new emitter field dynamically
    const addEmitter = () => {
        setFlashlight({
            ...flashlight,
            emitters: [...flashlight.emitters, { type: '', driver_type: '', max_lumens: '', max_throw: '', stated_cct: '', measured_cct: '', measured_ra: '', measured_duv: '', measured_lux: '', distance_tested: '' }]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        fetch('https://blackumbra.com/api/flashlights', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(flashlight),
        })
        .then(response => {
            if (response.ok) {
                setMessage('Flashlight added successfully');
                setError('');
                fetchBatteries();  // Refresh available batteries after adding flashlight
                
                if (onFlashlightAdded && typeof onFlashlightAdded === 'function') {
                    onFlashlightAdded();  // Trigger the refresh of flashlights and tables
                }
            } else {
                return response.json().then((data) => {
                    setError(`Error: ${data.message || 'Failed to add flashlight'}`);
                });
            }
        })
        .catch((error) => {
            console.error('Error during submission:', error);
            setError('Error: Failed to submit flashlight. Check console for details.');
        });
    };

    return (
        <div>
            <h2>Add Flashlight</h2>
            <form onSubmit={handleSubmit}>
                {/* Form fields */}
                <input name="make" placeholder="Make" value={flashlight.make} onChange={handleChange} />
                <input name="model" placeholder="Model" value={flashlight.model} onChange={handleChange} />
                <input name="battery_size" placeholder="Battery Size" value={flashlight.battery_size} onChange={handleChange} />
                <input name="battery_chemistry" placeholder="Battery Chemistry" value={flashlight.battery_chemistry} onChange={handleChange} />
                {/* New field for max_slots */}
                <label>Max Battery Slots:</label>
                <input
                    name="max_slots"
                    type="number"
                    min="1"
                    value={flashlight.max_slots}
                    onChange={handleChange}
                    placeholder="Max Slots"
                />
                <input name="ip_rating" placeholder="IP Rating" value={flashlight.ip_rating} onChange={handleChange} />
                <input name="date_acquired" type="date" value={flashlight.date_acquired} onChange={handleChange} />
                <input name="nickname" placeholder="Nickname (Optional)" value={flashlight.nickname} onChange={handleChange} />
                <input name="headstamp" placeholder="Headstamp (Optional)" value={flashlight.headstamp} onChange={handleChange} />
                <input name="notes" placeholder="Notes (Optional)" value={flashlight.notes} onChange={handleChange} />

                {/* Assign Battery */}
                <label>Assign Battery</label>
                <select name="assigned_battery_id" onChange={handleChange} value={flashlight.assigned_battery_id}>
                    <option value="">-- Select Battery --</option>
                    {availableBatteries.length > 0 ? (
                        availableBatteries.map(battery => (
                            <option key={battery.id} value={battery.id}>{battery.barcode}</option>
                        ))
                    ) : (
                        <option disabled>No batteries available</option>
                    )}
                </select>

                <h3>Emitters</h3>
                {flashlight.emitters.map((emitter, index) => (
                    <div key={index}>
                        <input name="type" placeholder="Emitter Type" value={emitter.type} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="driver_type" placeholder="Driver Type" value={emitter.driver_type} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="max_lumens" placeholder="Max Lumens" value={emitter.max_lumens} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="max_throw" placeholder="Max Throw" value={emitter.max_throw} onChange={(e) => handleEmitterChange(e, index)} />
                        <input name="stated_cct" placeholder="Stated CCT" value={emitter.stated_cct} onChange={(e) => handleEmitterChange(e, index)} />
                    </div>
                ))}
                <button type="button" onClick={addEmitter}>Add Another Emitter</button>

                <button type="submit">Submit</button>
                {message && <p style={{ color: 'green' }}>{message}</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
        </div>
    );
}

export default AddFlashlightForm;
